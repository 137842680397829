var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.loading ? _c('auction-loading') : _vm._e(), _c('AuctionsHeader'), _c('div', {
    staticClass: "auction"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "auction-info"
  }, [_c('h1', {
    staticClass: "auction-info__title"
  }, [_vm._v(_vm._s(_vm.currentAuction) + " YEAR Auction")]), _c('div', {
    staticClass: "auction-info__cont"
  }, [_c('div', {
    staticClass: "auction-info__block"
  }, [_c('div', {
    staticClass: "auction-info__head"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/overall.svg"),
      "alt": "Overall info"
    }
  }), _c('p', {
    staticClass: "auction-info__name"
  }, [_vm._v(_vm._s(_vm.$t("main.overall_info")))])]), _c('div', {
    staticClass: "auction-info__details"
  }, [_vm.storage && _vm.storage.active_bids && Object.keys(_vm.storage.active_bids).length ? _c('p', [_vm._v(_vm._s(Object.values(_vm.storage.active_bids)[0].amount / 1000000) + " xtz")]) : _vm._e(), _c('span', [_vm._v("1st " + _vm._s(_vm.$t("main.place")))])]), _c('div', {
    staticClass: "auction-info__details"
  }, [_c('p', [_vm._v(_vm._s(_vm.placeAmount))]), _c('span', [_vm._v(_vm._s(_vm.placeNumber) + " " + _vm._s(_vm.$t("main.place")))])]), _c('div', {
    staticClass: "auction-info__details"
  }, [_c('p', [_vm._v(_vm._s(_vm.storage.bid_count))]), _c('span', [_vm._v(_vm._s(_vm.$t("main.bids")))])])]), _c('div', {
    staticClass: "auction-info__block"
  }, [_c('div', {
    staticClass: "auction-info__head",
    class: _vm.auctionFinished ? 'ptb-16' : ''
  }, [_c('img', {
    attrs: {
      "src": require("../../public/time.svg"),
      "alt": "Overall info"
    }
  }), _c('p', {
    staticClass: "auction-info__name"
  }, [_vm._v(_vm._s(_vm.$t("main.time_left")))])]), _c('Timer', {
    attrs: {
      "finish": _vm.auctionFinished,
      "date": _vm.auctionDate
    },
    on: {
      "auctionFinish": function auctionFinish(type) {
        return _vm.isAuctionFinished(type);
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "auction-bids",
    class: !_vm.isConnected ? 'not-logged' : _vm.auctionFinished ? 'closed' : '',
    attrs: {
      "id": "bids"
    }
  }, [_c('h2', {
    staticClass: "auction-info__title results"
  }, [_vm._v(_vm._s(_vm.auctionFinished ? _vm.$t("main.results") : _vm.$t("main.bids")))]), _c('div', {
    staticClass: "auction-bids__cont"
  }, [_vm.isConnected && !_vm.auctionFinished ? _c('div', {
    staticClass: "auction-bids__header"
  }, [_c('div', {
    class: [_vm.isMakeBid ? 'auction-bids__tab active' : 'auction-bids__tab'],
    on: {
      "click": _vm.makeBid
    }
  }, [_vm._v(_vm._s(_vm.$t("main.make_new_bid")))]), _c('div', {
    class: [_vm.isIncreaseBid ? 'auction-bids__tab active' : 'auction-bids__tab'],
    on: {
      "click": _vm.increaseBid
    }
  }, [_vm._v(_vm._s(_vm.$t("main.increase_bid")))]), _c('div', {
    staticClass: "auction-bids__prompt",
    on: {
      "click": _vm.closeBackdrop
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.info")))]), _c('svg', {
    attrs: {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M0.40332 11C0.40332 8.18957 1.51977 5.49423 3.50705 3.50694C5.49434 1.51966 8.18967 0.403214 11.0001 0.403214C13.8106 0.403214 16.5059 1.51966 18.4932 3.50694C20.4805 5.49423 21.5969 8.18957 21.5969 11C21.5969 13.8105 20.4805 16.5058 18.4932 18.4931C16.5059 20.4804 13.8106 21.5968 11.0001 21.5968C8.18967 21.5968 5.49434 20.4804 3.50705 18.4931C1.51977 16.5058 0.40332 13.8105 0.40332 11ZM11.0001 1.92321C8.5928 1.92321 6.28408 2.87952 4.58185 4.58175C2.87962 6.28398 1.92332 8.5927 1.92332 11C1.92332 13.4073 2.87962 15.7161 4.58185 17.4183C6.28408 19.1205 8.5928 20.0768 11.0001 20.0768C13.4074 20.0768 15.7162 19.1205 17.4184 17.4183C19.1206 15.7161 20.0769 13.4073 20.0769 11C20.0769 8.5927 19.1206 6.28398 17.4184 4.58175C15.7162 2.87952 13.4074 1.92321 11.0001 1.92321Z",
      "fill": "#CCCCCC"
    }
  }), _c('path', {
    attrs: {
      "d": "M9.41031 9.57959H12.5899V16.2753C12.5899 16.8276 12.1422 17.2753 11.5899 17.2753H10.4103C9.85803 17.2753 9.41031 16.8276 9.41031 16.2753V9.57959ZM11.0001 7.96056C10.428 7.96056 9.96369 7.81049 9.60728 7.51035C9.26024 7.20083 9.08673 6.81159 9.08673 6.34263C9.08673 5.87366 9.26024 5.48911 9.60728 5.18897C9.96369 4.87945 10.428 4.72469 11.0001 4.72469C11.5816 4.72469 12.0459 4.87007 12.3929 5.16083C12.74 5.45159 12.9135 5.82676 12.9135 6.28635C12.9135 6.77407 12.74 7.17738 12.3929 7.49628C12.0459 7.8058 11.5816 7.96056 11.0001 7.96056Z",
      "fill": "#CCCCCC"
    }
  })])])]) : _vm._e(), _vm.isConnected && !_vm.auctionFinished ? _c('div', {
    class: [_vm.isMakeBid ? 'auction-bids__body active' : 'auction-bids__body']
  }, [_c('div', {
    staticClass: "auction-bids__col"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.rank")))]), _c('div', {
    staticClass: "auction-bids__counter rank"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v("№")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rank,
      expression: "rank"
    }],
    attrs: {
      "type": "number",
      "min": "0"
    },
    domProps: {
      "value": _vm.rank
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.rank = $event.target.value;
      }, function ($event) {
        _vm.rank = _vm.rank.replace(/[^0-9]/g, ''), _vm.rankCalculation();
      }]
    }
  }), _c('button', {
    staticClass: "plus",
    on: {
      "click": function click($event) {
        return _vm.updateRank('+');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.899555 4.7329C0.896889 4.8368 0.932029 4.93883 1.00014 5.02481C1.06826 5.11079 1.16598 5.17644 1.27964 5.21264C1.39329 5.24883 1.51727 5.25378 1.63435 5.22678C1.75144 5.19978 1.85575 5.14219 1.93289 5.062L4.99082 2.00243L8.04768 5.062C8.09525 5.11747 8.15563 5.16392 8.22511 5.19845C8.29459 5.23298 8.37166 5.25485 8.45145 5.26267C8.53124 5.2705 8.61204 5.26411 8.68882 5.24393C8.7656 5.22374 8.83672 5.19018 8.89767 5.14535C8.95862 5.10053 9.00806 5.04539 9.043 4.98341C9.07794 4.92143 9.09756 4.85393 9.10066 4.78514C9.10376 4.71635 9.0903 4.64776 9.06103 4.58365C9.03177 4.51954 8.98738 4.46131 8.93064 4.41259L5.43402 0.909657C5.37925 0.854648 5.31127 0.810475 5.23466 0.780186C5.15804 0.749896 5.07465 0.734211 4.99028 0.734211C4.90591 0.734211 4.82253 0.749896 4.74591 0.780186C4.6693 0.810475 4.60126 0.854648 4.54649 0.909657L1.0465 4.41259C0.954802 4.50108 0.902669 4.61458 0.899421 4.7329L0.899555 4.7329Z",
      "fill": "black"
    }
  })])]), _c('button', {
    staticClass: "minus",
    on: {
      "click": function click($event) {
        return _vm.updateRank('-');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z",
      "fill": "black"
    }
  })])])])]), _c('div', {
    staticClass: "auction-bids__col"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.price")))]), _c('div', {
    staticClass: "auction-bids__counter price"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.price,
      expression: "price"
    }],
    attrs: {
      "type": "number",
      "step": "1",
      "placeholder": ""
    },
    domProps: {
      "value": _vm.price
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.price = $event.target.value;
      }, _vm.changePrice]
    }
  }), _c('span', {
    staticClass: "price"
  }, [_vm._v("xtz")]), _c('button', {
    staticClass: "plus",
    on: {
      "click": function click($event) {
        return _vm.updatePrice('+');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.899555 4.7329C0.896889 4.8368 0.932029 4.93883 1.00014 5.02481C1.06826 5.11079 1.16598 5.17644 1.27964 5.21264C1.39329 5.24883 1.51727 5.25378 1.63435 5.22678C1.75144 5.19978 1.85575 5.14219 1.93289 5.062L4.99082 2.00243L8.04768 5.062C8.09525 5.11747 8.15563 5.16392 8.22511 5.19845C8.29459 5.23298 8.37166 5.25485 8.45145 5.26267C8.53124 5.2705 8.61204 5.26411 8.68882 5.24393C8.7656 5.22374 8.83672 5.19018 8.89767 5.14535C8.95862 5.10053 9.00806 5.04539 9.043 4.98341C9.07794 4.92143 9.09756 4.85393 9.10066 4.78514C9.10376 4.71635 9.0903 4.64776 9.06103 4.58365C9.03177 4.51954 8.98738 4.46131 8.93064 4.41259L5.43402 0.909657C5.37925 0.854648 5.31127 0.810475 5.23466 0.780186C5.15804 0.749896 5.07465 0.734211 4.99028 0.734211C4.90591 0.734211 4.82253 0.749896 4.74591 0.780186C4.6693 0.810475 4.60126 0.854648 4.54649 0.909657L1.0465 4.41259C0.954802 4.50108 0.902669 4.61458 0.899421 4.7329L0.899555 4.7329Z",
      "fill": "black"
    }
  })])]), _c('button', {
    staticClass: "minus",
    attrs: {
      "disabled": _vm.disabledMinus
    },
    on: {
      "click": function click($event) {
        return _vm.updatePrice('-');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z",
      "fill": "black"
    }
  })])])])]), _c('div', {
    staticClass: "auction-bids__col"
  }, [_c('button', {
    staticClass: "auction-bids__btn bid",
    attrs: {
      "disabled": _vm.bidButtonDisabledAmount
    },
    on: {
      "click": _vm.buyAuction
    }
  }, [_vm._v(_vm._s(_vm.$t("main.bid")))])])]) : _vm._e(), _vm.isConnected && !_vm.auctionFinished ? _c('div', {
    class: [_vm.isIncreaseBid ? 'auction-bids__body active' : 'auction-bids__body']
  }, [_c('div', {
    staticClass: "auction-bids__col"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.rank")))]), _c('div', {
    staticClass: "auction-bids__counter rank"
  }, [_c('span', {
    staticClass: "num"
  }, [_vm._v("№")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.rank,
      expression: "rank"
    }],
    attrs: {
      "type": "number",
      "placeholder": "1"
    },
    domProps: {
      "value": _vm.rank
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.rank = $event.target.value;
      }, function ($event) {
        _vm.rank = _vm.rank.replace(/[^0-9]/g, ''), _vm.rankCalculation();
      }]
    }
  }), _c('button', {
    staticClass: "plus",
    on: {
      "click": function click($event) {
        return _vm.updateRank('+');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.899555 4.7329C0.896889 4.8368 0.932029 4.93883 1.00014 5.02481C1.06826 5.11079 1.16598 5.17644 1.27964 5.21264C1.39329 5.24883 1.51727 5.25378 1.63435 5.22678C1.75144 5.19978 1.85575 5.14219 1.93289 5.062L4.99082 2.00243L8.04768 5.062C8.09525 5.11747 8.15563 5.16392 8.22511 5.19845C8.29459 5.23298 8.37166 5.25485 8.45145 5.26267C8.53124 5.2705 8.61204 5.26411 8.68882 5.24393C8.7656 5.22374 8.83672 5.19018 8.89767 5.14535C8.95862 5.10053 9.00806 5.04539 9.043 4.98341C9.07794 4.92143 9.09756 4.85393 9.10066 4.78514C9.10376 4.71635 9.0903 4.64776 9.06103 4.58365C9.03177 4.51954 8.98738 4.46131 8.93064 4.41259L5.43402 0.909657C5.37925 0.854648 5.31127 0.810475 5.23466 0.780186C5.15804 0.749896 5.07465 0.734211 4.99028 0.734211C4.90591 0.734211 4.82253 0.749896 4.74591 0.780186C4.6693 0.810475 4.60126 0.854648 4.54649 0.909657L1.0465 4.41259C0.954802 4.50108 0.902669 4.61458 0.899421 4.7329L0.899555 4.7329Z",
      "fill": "black"
    }
  })])]), _c('button', {
    staticClass: "minus",
    on: {
      "click": function click($event) {
        return _vm.updateRank('-');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z",
      "fill": "black"
    }
  })])])])]), _c('div', {
    staticClass: "auction-bids__col"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.price")))]), _c('div', {
    staticClass: "auction-bids__counter price"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.price,
      expression: "price"
    }],
    attrs: {
      "type": "number",
      "step": "1",
      "min": "1",
      "placeholder": ""
    },
    domProps: {
      "value": _vm.price
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.price = $event.target.value;
      }, _vm.changePrice]
    }
  }), _c('span', {
    staticClass: "price"
  }, [_vm._v("xtz")]), _c('button', {
    staticClass: "plus",
    on: {
      "click": function click($event) {
        return _vm.updatePrice('+');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0.899555 4.7329C0.896889 4.8368 0.932029 4.93883 1.00014 5.02481C1.06826 5.11079 1.16598 5.17644 1.27964 5.21264C1.39329 5.24883 1.51727 5.25378 1.63435 5.22678C1.75144 5.19978 1.85575 5.14219 1.93289 5.062L4.99082 2.00243L8.04768 5.062C8.09525 5.11747 8.15563 5.16392 8.22511 5.19845C8.29459 5.23298 8.37166 5.25485 8.45145 5.26267C8.53124 5.2705 8.61204 5.26411 8.68882 5.24393C8.7656 5.22374 8.83672 5.19018 8.89767 5.14535C8.95862 5.10053 9.00806 5.04539 9.043 4.98341C9.07794 4.92143 9.09756 4.85393 9.10066 4.78514C9.10376 4.71635 9.0903 4.64776 9.06103 4.58365C9.03177 4.51954 8.98738 4.46131 8.93064 4.41259L5.43402 0.909657C5.37925 0.854648 5.31127 0.810475 5.23466 0.780186C5.15804 0.749896 5.07465 0.734211 4.99028 0.734211C4.90591 0.734211 4.82253 0.749896 4.74591 0.780186C4.6693 0.810475 4.60126 0.854648 4.54649 0.909657L1.0465 4.41259C0.954802 4.50108 0.902669 4.61458 0.899421 4.7329L0.899555 4.7329Z",
      "fill": "black"
    }
  })])]), _c('button', {
    staticClass: "minus",
    attrs: {
      "disabled": _vm.disabledMinus
    },
    on: {
      "click": function click($event) {
        return _vm.updatePrice('-');
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z",
      "fill": "black"
    }
  })])])])]), _c('div', {
    staticClass: "auction-bids__col"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("main.totalPrice")))]), _c('div', {
    staticClass: "auction-bids__counter total-price"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.totalPriceNew,
      expression: "totalPriceNew"
    }],
    attrs: {
      "type": "number",
      "readonly": "",
      "placeholder": ""
    },
    domProps: {
      "value": _vm.totalPriceNew
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.totalPriceNew = $event.target.value;
      }
    }
  }), _c('span', {
    staticClass: "price"
  }, [_vm._v("xtz")])])]), _c('div', {
    staticClass: "auction-bids__col"
  }, [_c('button', {
    staticClass: "auction-bids__btn bid",
    attrs: {
      "disabled": _vm.bidButtonDisabled
    },
    on: {
      "click": _vm.buyAuction
    }
  }, [_vm._v(_vm._s(_vm.$t("main.bid")))])])]) : _vm._e(), !_vm.isConnected ? _c('div', {
    staticClass: "auction-bids__alert"
  }, [_c('svg', {
    attrs: {
      "width": "29",
      "height": "25",
      "viewBox": "0 0 29 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.6159 15.042L13.3582 11.1902C13.3078 10.4152 13.0434 9.16265 13.6353 8.53297C14.0857 8.04859 15.1523 7.96528 15.4759 8.63662C15.751 9.42551 15.8119 10.2733 15.6522 11.0934L15.3073 15.0585C15.2925 15.4317 15.2108 15.7992 15.0661 16.1435C15.0092 16.2549 14.9228 16.3486 14.8164 16.4145C14.71 16.4803 14.5876 16.5158 14.4625 16.5171C14.3374 16.5184 14.2143 16.4855 14.1066 16.4218C13.9988 16.3582 13.9106 16.2663 13.8513 16.1561C13.715 15.7993 13.6355 15.4234 13.6159 15.042ZM14.5081 20.3343C14.2026 20.3326 13.9093 20.2145 13.6877 20.0042C13.4662 19.7938 13.3331 19.507 13.3156 19.202C13.2981 18.897 13.3974 18.5968 13.5934 18.3624C13.7894 18.1281 14.0673 17.9772 14.3705 17.9405C14.5335 17.9208 14.6987 17.9347 14.8561 17.9813C15.0135 18.0279 15.1596 18.1063 15.2855 18.2116C15.4114 18.3169 15.5144 18.4468 15.5881 18.5935C15.6618 18.7401 15.7047 18.9003 15.7141 19.0641C15.7235 19.228 15.6993 19.392 15.6428 19.5461C15.5864 19.7003 15.499 19.8412 15.386 19.9602C15.273 20.0792 15.1368 20.1738 14.9858 20.2381C14.8348 20.3025 14.6722 20.3352 14.5081 20.3343Z",
      "fill": "#333333"
    }
  }), _c('path', {
    attrs: {
      "d": "M25.3433 24.1252H3.65682C3.1473 24.1173 2.64862 23.9769 2.20992 23.7176C1.77123 23.4583 1.40768 23.0892 1.1551 22.6466C0.902519 22.204 0.76963 21.7033 0.769531 21.1937C0.769433 20.6841 0.902129 20.1833 1.15454 19.7406L12.0104 2.33896C12.2741 1.91505 12.6417 1.56549 13.0783 1.3234C13.5149 1.0813 14.0061 0.954698 14.5054 0.955571C15.0046 0.956444 15.4954 1.08477 15.9312 1.32839C16.3669 1.572 16.7333 1.92285 16.9955 2.34768L27.8339 19.7203C28.0906 20.1627 28.2271 20.6645 28.2299 21.176C28.2327 21.6874 28.1018 22.1907 27.85 22.636C27.5983 23.0812 27.2345 23.4529 26.7948 23.7141C26.355 23.9753 25.8547 24.117 25.3433 24.1252ZM13.6659 3.34452L2.8111 20.7462C2.73105 20.894 2.6905 21.0599 2.69337 21.2279C2.69625 21.396 2.74244 21.5604 2.82749 21.7054C2.91253 21.8504 3.03356 21.9709 3.17884 22.0554C3.32413 22.1399 3.48877 22.1855 3.65682 22.1877H25.3433C25.5131 22.1851 25.6794 22.1383 25.8257 22.0518C25.972 21.9654 26.0932 21.8423 26.1774 21.6947C26.2616 21.5472 26.3059 21.3802 26.3059 21.2103C26.3059 21.0404 26.2616 20.8734 26.1774 20.7258C26.1774 20.7258 15.339 3.35033 15.3341 3.34452C15.2449 3.20408 15.1217 3.08844 14.9759 3.00832C14.8301 2.9282 14.6664 2.88619 14.5 2.88619C14.3337 2.88619 14.17 2.9282 14.0242 3.00832C13.8784 3.08844 13.7551 3.20408 13.6659 3.34452Z",
      "fill": "#333333"
    }
  })]), _c('span', [_vm._v(_vm._s(_vm.$t("main.please")) + ", "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.plugInWallet.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("main.connect_wallet")))]), _vm._v(" " + _vm._s(_vm.$t("main.to_make_bid")) + " ")])]) : _vm._e(), _vm.auctionFinished && _vm.isConnected ? _c('div', {
    staticClass: "auction-bids__finished"
  }, [_c('div', {
    staticClass: "auction-bids__closed"
  }, [_c('div', [_vm._v(_vm._s(_vm.currentAuction) + " YEAR Auction has ended")]), _c('p', [_vm._v("You can see NFT’s in the Explorer ")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Explore'
      }
    }
  }, [_vm._v("Explore NFTs")])], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "auction-image"
  }, [_c('img', {
    attrs: {
      "src": require("../../public/Lae-backup/pics/".concat(_vm.imageId, ".png")),
      "alt": "1 YEAR Auction"
    }
  })]), _c('div', {
    staticClass: "auction-list__outer"
  }, [_c('div', {
    staticClass: "auction-list__bids logged"
  }, [_c('div', [!_vm.isIncreaseBid ? _c('label', {
    staticClass: "auction-list__my",
    class: {
      checked: _vm.isMyBidsChecked
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('main.my_bids')) + " "), _c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isMyBidsChecked
    },
    on: {
      "change": function change($event) {
        return _vm.toggleMyBids(false);
      }
    }
  }), _c('span', {
    staticClass: "checkmark"
  })]) : _vm._e()])]), _c('div', {
    staticClass: "auction-list",
    class: {
      checked: _vm.isMyBidsChecked
    }
  }, [_c('div', {
    staticClass: "auction-list__header"
  }, [_c('div', {
    staticClass: "auction-list__col"
  }, [_vm._v(_vm._s(_vm.$t("table.rank")))]), _c('div', {
    staticClass: "auction-list__col"
  }, [_vm._v(_vm._s(_vm.$t("table.bid")))]), _c('div', {
    staticClass: "auction-list__col"
  }, [_vm._v(_vm._s(_vm.$t("table.date")))]), _c('div', {
    staticClass: "auction-list__col"
  }, [_vm._v(_vm._s(_vm.$t("table.season")))]), _c('div', {
    staticClass: "auction-list__col"
  }, [_vm._v(_vm._s(_vm.$t("table.address")))]), _c('div', {
    staticClass: "auction-list__bids",
    class: _vm.isConnected ? 'logged' : ''
  }, [!_vm.isIncreaseBid ? _c('label', {
    staticClass: "auction-list__my"
  }, [_vm._v(" " + _vm._s(_vm.$t("main.my_bids")) + " "), _c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.isMyBidsChecked
    },
    on: {
      "change": function change($event) {
        return _vm.toggleMyBids(false);
      }
    }
  }), _c('span', {
    staticClass: "checkmark"
  })]) : _vm._e()])]), _c('div', {
    staticClass: "auction-list__items"
  }, _vm._l(_vm.transactions, function (transaction, index) {
    return _c('div', {
      key: index,
      staticClass: "auction-list__item",
      class: _vm.activeAuctionItem === transaction.key ? 'auction-list-item-active' : '',
      on: {
        "click": function click($event) {
          _vm.isIncreaseBid ? _vm.updateAmount(transaction) : '';
        }
      }
    }, [_c('div', {
      staticClass: "auction-list__col",
      class: !transaction.statusWin ? 'fault' : 'active'
    }, [_c('p', [_vm._v(_vm._s(_vm.$t("table.rank")))]), _vm._v(_vm._s(transaction.rank) + " ")]), _c('div', {
      staticClass: "auction-list__col",
      attrs: {
        "title": transaction.value.amount / 1000000 + 'xtz'
      }
    }, [_c('p', [_vm._v(_vm._s(_vm.$t("table.bid")))]), _vm._v(_vm._s(transaction.value.amount / 1000000) + " xtz")]), _c('div', {
      staticClass: "auction-list__col"
    }, [_c('p', [_vm._v(_vm._s(_vm.$t("table.date")))]), _vm._v(_vm._s(_vm._f("moment")(transaction.value.ts, "from", "now")))]), _c('div', {
      staticClass: "auction-list__col"
    }, [_c('p', [_vm._v(_vm._s(_vm.$t("table.season")))]), _vm._v(_vm._s(_vm.seasonConverter(transaction.value.season)))]), _c('div', {
      staticClass: "auction-list__col"
    }, [_vm._v(_vm._s(transaction.value.bidder.substr(0, 3)) + "..." + _vm._s(transaction.value.bidder.substr(transaction.value.bidder.length - 4)))])]);
  }), 0)])]), _c('div', {
    staticClass: "auction-history",
    class: !_vm.isConnected ? 'not-logged' : ''
  }, [_c('h3', {
    staticClass: "auction-info__title"
  }, [_vm._v(_vm._s(_vm.$t("main.bids_history")))]), _c('div', {
    staticClass: "auction-history__cont"
  }, [_c('div', {
    staticClass: "auction-history__scroll"
  }, [!_vm.isConnected ? _c('div', {
    staticClass: "auction-history__alert"
  }, [_c('svg', {
    attrs: {
      "width": "29",
      "height": "25",
      "viewBox": "0 0 29 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M13.6159 15.042L13.3582 11.1902C13.3078 10.4152 13.0434 9.16265 13.6353 8.53297C14.0857 8.04859 15.1523 7.96528 15.4759 8.63662C15.751 9.42551 15.8119 10.2733 15.6522 11.0934L15.3073 15.0585C15.2925 15.4317 15.2108 15.7992 15.0661 16.1435C15.0092 16.2549 14.9228 16.3486 14.8164 16.4145C14.71 16.4803 14.5876 16.5158 14.4625 16.5171C14.3374 16.5184 14.2143 16.4855 14.1066 16.4218C13.9988 16.3582 13.9106 16.2663 13.8513 16.1561C13.715 15.7993 13.6355 15.4234 13.6159 15.042ZM14.5081 20.3343C14.2026 20.3326 13.9093 20.2145 13.6877 20.0042C13.4662 19.7938 13.3331 19.507 13.3156 19.202C13.2981 18.897 13.3974 18.5968 13.5934 18.3624C13.7894 18.1281 14.0673 17.9772 14.3705 17.9405C14.5335 17.9208 14.6987 17.9347 14.8561 17.9813C15.0135 18.0279 15.1596 18.1063 15.2855 18.2116C15.4114 18.3169 15.5144 18.4468 15.5881 18.5935C15.6618 18.7401 15.7047 18.9003 15.7141 19.0641C15.7235 19.228 15.6993 19.392 15.6428 19.5461C15.5864 19.7003 15.499 19.8412 15.386 19.9602C15.273 20.0792 15.1368 20.1738 14.9858 20.2381C14.8348 20.3025 14.6722 20.3352 14.5081 20.3343Z",
      "fill": "#333333"
    }
  }), _c('path', {
    attrs: {
      "d": "M25.3433 24.1252H3.65682C3.1473 24.1173 2.64862 23.9769 2.20992 23.7176C1.77123 23.4583 1.40768 23.0892 1.1551 22.6466C0.902519 22.204 0.76963 21.7033 0.769531 21.1937C0.769433 20.6841 0.902129 20.1833 1.15454 19.7406L12.0104 2.33896C12.2741 1.91505 12.6417 1.56549 13.0783 1.3234C13.5149 1.0813 14.0061 0.954698 14.5054 0.955571C15.0046 0.956444 15.4954 1.08477 15.9312 1.32839C16.3669 1.572 16.7333 1.92285 16.9955 2.34768L27.8339 19.7203C28.0906 20.1627 28.2271 20.6645 28.2299 21.176C28.2327 21.6874 28.1018 22.1907 27.85 22.636C27.5983 23.0812 27.2345 23.4529 26.7948 23.7141C26.355 23.9753 25.8547 24.117 25.3433 24.1252ZM13.6659 3.34452L2.8111 20.7462C2.73105 20.894 2.6905 21.0599 2.69337 21.2279C2.69625 21.396 2.74244 21.5604 2.82749 21.7054C2.91253 21.8504 3.03356 21.9709 3.17884 22.0554C3.32413 22.1399 3.48877 22.1855 3.65682 22.1877H25.3433C25.5131 22.1851 25.6794 22.1383 25.8257 22.0518C25.972 21.9654 26.0932 21.8423 26.1774 21.6947C26.2616 21.5472 26.3059 21.3802 26.3059 21.2103C26.3059 21.0404 26.2616 20.8734 26.1774 20.7258C26.1774 20.7258 15.339 3.35033 15.3341 3.34452C15.2449 3.20408 15.1217 3.08844 14.9759 3.00832C14.8301 2.9282 14.6664 2.88619 14.5 2.88619C14.3337 2.88619 14.17 2.9282 14.0242 3.00832C13.8784 3.08844 13.7551 3.20408 13.6659 3.34452Z",
      "fill": "#333333"
    }
  })]), _c('span', [_vm._v(_vm._s(_vm.$t("main.please")) + ", "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.plugInWallet.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("main.connect_wallet")))]), _vm._v(" " + _vm._s(_vm.$t("main.to_make_bid")) + " ")])]) : _vm._e(), _vm.isConnected ? _c('div', {
    staticClass: "auction-history__header"
  }, [_c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.date")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.auction")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.season")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.rank")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.bid")))]), _c('div', {
    staticClass: "auction-history__col"
  }, [_vm._v(_vm._s(_vm.$t("table.address")))]), _c('div', {
    staticClass: "auction-history__col"
  }), _c('div', {
    staticClass: "auction-history__col"
  })]) : _vm._e(), _vm.isConnected ? _c('div', {
    staticClass: "auction-history__items"
  }, _vm._l(_vm.userTransactions, function (transaction, index) {
    return _c('div', {
      key: index,
      staticClass: "auction-history__item"
    }, [_c('div', {
      staticClass: "auction-history__col"
    }, [_vm._v(_vm._s(_vm.dateConverter(transaction.value.ts)))]), _c('div', {
      staticClass: "auction-history__col"
    }, [_vm._v(_vm._s(transaction.year) + " " + _vm._s(_vm.$t("table.year")))]), _c('div', {
      staticClass: "auction-history__col"
    }, [_c('p', [_vm._v("Season")]), _vm._v(_vm._s(_vm.seasonConverter(transaction.value.season)))]), _c('div', {
      staticClass: "auction-history__col"
    }, [_c('p', [_vm._v("Rank")]), _vm._v(_vm._s(transaction.rank))]), _c('div', {
      staticClass: "auction-history__col"
    }, [_c('p', [_vm._v("Bid")]), _vm._v(_vm._s(transaction.value.amount / 1000000) + " xtz")]), _c('div', {
      staticClass: "auction-history__col "
    }, [_vm._v(_vm._s(transaction.value.bidder.substr(0, 3)) + "..." + _vm._s(transaction.value.bidder.substr(transaction.value.bidder.length - 4)))]), _c('div', {
      staticClass: "auction-history__col"
    }, [transaction.active && !transaction.statusWin && !_vm.auctionFinished ? _c('button', {
      staticClass: "auction-history__btn increase",
      on: {
        "click": function click($event) {
          _vm.increaseBid(), _vm.updateAmount(transaction);
        }
      }
    }, [_vm._v("Increase")]) : _vm._e()]), _c('div', {
      staticClass: "auction-history__col"
    }, [transaction.active && !transaction.statusWin ? _c('button', {
      staticClass: "auction-history__btn withdraw",
      on: {
        "click": function click($event) {
          return _vm.openModal(transaction);
        }
      }
    }, [_vm._v("Withdraw")]) : _vm._e(), transaction.statusWin && transaction.active && _vm.auctionFinished ? _c('button', {
      staticClass: "auction-history__btn increase",
      on: {
        "click": function click($event) {
          return _vm.takeNft(transaction);
        }
      }
    }, [_vm._v("Take nft")]) : _vm._e()])]);
  }), 0) : _vm._e()])])])])]), _c('Faq'), _vm.isModalOpen ? _c('WithdrawalModal', {
    attrs: {
      "data": _vm.userData,
      "currentAuctionContract": _vm.currentAuctionContract,
      "close": function close(status) {
        return _vm.close(status);
      }
    }
  }) : _vm._e(), _c('div', {
    class: [_vm.isBackdrop ? 'backdrop open' : 'backdrop'],
    on: {
      "click": _vm.closeBackdrop
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }