<template>
  <div>
  <auction-loading v-if="loading"/>
  <AuctionsHeader />
  <div class="auction">
    <div class="wrapper">
      <div class="auction-info">
        <h1 class="auction-info__title">{{currentAuction}} YEAR Auction</h1>
        <div class="auction-info__cont">
          <div class="auction-info__block">
            <div class="auction-info__head">
              <img src="../../public/overall.svg" alt="Overall info">
              <p class="auction-info__name">{{ $t("main.overall_info") }}</p>
            </div>
            <div class="auction-info__details">
              <p v-if="storage && storage.active_bids && Object.keys(storage.active_bids).length">{{Object.values(storage.active_bids)[0].amount / 1000000}} xtz</p>
              <span>1st {{ $t("main.place") }}</span>
            </div>
            <div class="auction-info__details">
              <p>{{placeAmount}}</p>
              <span>{{placeNumber}} {{ $t("main.place") }}</span>
            </div>
            <div class="auction-info__details">
              <p>{{storage.bid_count}}</p>
              <span>{{ $t("main.bids") }}</span>
            </div>
          </div>
          <div class="auction-info__block">
            <div class="auction-info__head" :class="auctionFinished ? 'ptb-16' : ''">
              <img src="../../public/time.svg" alt="Overall info">
              <p class="auction-info__name">{{ $t("main.time_left") }}</p>
            </div>
            <Timer :finish="auctionFinished" @auctionFinish="(type) => isAuctionFinished(type)" :date="auctionDate" />
          </div>
        </div>
      </div>
      <div class="auction-bids" :class="!isConnected ? 'not-logged' : auctionFinished ? 'closed' : ''" id="bids"><!-- closed class if auction closed--> <!-- not-logged class if wallet not connected-->
        <h2 class="auction-info__title results">{{auctionFinished ? $t("main.results") :  $t("main.bids")}}</h2>
        <div class="auction-bids__cont">
          <div v-if="isConnected && !auctionFinished" class="auction-bids__header">
            <div v-bind:class="[isMakeBid ? 'auction-bids__tab active' : 'auction-bids__tab']" @click="makeBid">{{ $t("main.make_new_bid") }}</div>
            <div v-bind:class="[isIncreaseBid ? 'auction-bids__tab active' : 'auction-bids__tab']" @click="increaseBid">{{ $t("main.increase_bid") }}</div>
            <div class="auction-bids__prompt" @click="closeBackdrop">
              <span>{{ $t("main.info") }}</span>
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.40332 11C0.40332 8.18957 1.51977 5.49423 3.50705 3.50694C5.49434 1.51966 8.18967 0.403214 11.0001 0.403214C13.8106 0.403214 16.5059 1.51966 18.4932 3.50694C20.4805 5.49423 21.5969 8.18957 21.5969 11C21.5969 13.8105 20.4805 16.5058 18.4932 18.4931C16.5059 20.4804 13.8106 21.5968 11.0001 21.5968C8.18967 21.5968 5.49434 20.4804 3.50705 18.4931C1.51977 16.5058 0.40332 13.8105 0.40332 11ZM11.0001 1.92321C8.5928 1.92321 6.28408 2.87952 4.58185 4.58175C2.87962 6.28398 1.92332 8.5927 1.92332 11C1.92332 13.4073 2.87962 15.7161 4.58185 17.4183C6.28408 19.1205 8.5928 20.0768 11.0001 20.0768C13.4074 20.0768 15.7162 19.1205 17.4184 17.4183C19.1206 15.7161 20.0769 13.4073 20.0769 11C20.0769 8.5927 19.1206 6.28398 17.4184 4.58175C15.7162 2.87952 13.4074 1.92321 11.0001 1.92321Z" fill="#CCCCCC"/>
                <path d="M9.41031 9.57959H12.5899V16.2753C12.5899 16.8276 12.1422 17.2753 11.5899 17.2753H10.4103C9.85803 17.2753 9.41031 16.8276 9.41031 16.2753V9.57959ZM11.0001 7.96056C10.428 7.96056 9.96369 7.81049 9.60728 7.51035C9.26024 7.20083 9.08673 6.81159 9.08673 6.34263C9.08673 5.87366 9.26024 5.48911 9.60728 5.18897C9.96369 4.87945 10.428 4.72469 11.0001 4.72469C11.5816 4.72469 12.0459 4.87007 12.3929 5.16083C12.74 5.45159 12.9135 5.82676 12.9135 6.28635C12.9135 6.77407 12.74 7.17738 12.3929 7.49628C12.0459 7.8058 11.5816 7.96056 11.0001 7.96056Z" fill="#CCCCCC"/>
              </svg>
            </div>
          </div>
          <div v-if="isConnected && !auctionFinished" v-bind:class="[isMakeBid ? 'auction-bids__body active' : 'auction-bids__body']">
            <div class="auction-bids__col">
              <span>{{ $t("main.rank") }}</span>
              <div class="auction-bids__counter rank">
                <span class="num">№</span>
                <input type="number" @input="rank = rank.replace(/[^0-9]/g,''), rankCalculation()" v-model="rank" min="0">
                <button class="plus" @click="updateRank('+')">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.899555 4.7329C0.896889 4.8368 0.932029 4.93883 1.00014 5.02481C1.06826 5.11079 1.16598 5.17644 1.27964 5.21264C1.39329 5.24883 1.51727 5.25378 1.63435 5.22678C1.75144 5.19978 1.85575 5.14219 1.93289 5.062L4.99082 2.00243L8.04768 5.062C8.09525 5.11747 8.15563 5.16392 8.22511 5.19845C8.29459 5.23298 8.37166 5.25485 8.45145 5.26267C8.53124 5.2705 8.61204 5.26411 8.68882 5.24393C8.7656 5.22374 8.83672 5.19018 8.89767 5.14535C8.95862 5.10053 9.00806 5.04539 9.043 4.98341C9.07794 4.92143 9.09756 4.85393 9.10066 4.78514C9.10376 4.71635 9.0903 4.64776 9.06103 4.58365C9.03177 4.51954 8.98738 4.46131 8.93064 4.41259L5.43402 0.909657C5.37925 0.854648 5.31127 0.810475 5.23466 0.780186C5.15804 0.749896 5.07465 0.734211 4.99028 0.734211C4.90591 0.734211 4.82253 0.749896 4.74591 0.780186C4.6693 0.810475 4.60126 0.854648 4.54649 0.909657L1.0465 4.41259C0.954802 4.50108 0.902669 4.61458 0.899421 4.7329L0.899555 4.7329Z" fill="black"/>
                  </svg>
                </button>
                <button class="minus" @click="updateRank('-')">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z" fill="black"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="auction-bids__col">
              <span>{{ $t("main.price") }}</span>
              <div class="auction-bids__counter price">
                <input type="number" step="1" @input="changePrice"  v-model="price" placeholder="">
                <span class="price">xtz</span>
                <button class="plus" @click="updatePrice('+')">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.899555 4.7329C0.896889 4.8368 0.932029 4.93883 1.00014 5.02481C1.06826 5.11079 1.16598 5.17644 1.27964 5.21264C1.39329 5.24883 1.51727 5.25378 1.63435 5.22678C1.75144 5.19978 1.85575 5.14219 1.93289 5.062L4.99082 2.00243L8.04768 5.062C8.09525 5.11747 8.15563 5.16392 8.22511 5.19845C8.29459 5.23298 8.37166 5.25485 8.45145 5.26267C8.53124 5.2705 8.61204 5.26411 8.68882 5.24393C8.7656 5.22374 8.83672 5.19018 8.89767 5.14535C8.95862 5.10053 9.00806 5.04539 9.043 4.98341C9.07794 4.92143 9.09756 4.85393 9.10066 4.78514C9.10376 4.71635 9.0903 4.64776 9.06103 4.58365C9.03177 4.51954 8.98738 4.46131 8.93064 4.41259L5.43402 0.909657C5.37925 0.854648 5.31127 0.810475 5.23466 0.780186C5.15804 0.749896 5.07465 0.734211 4.99028 0.734211C4.90591 0.734211 4.82253 0.749896 4.74591 0.780186C4.6693 0.810475 4.60126 0.854648 4.54649 0.909657L1.0465 4.41259C0.954802 4.50108 0.902669 4.61458 0.899421 4.7329L0.899555 4.7329Z" fill="black"/>
                  </svg>
                </button>
                <button class="minus" :disabled="disabledMinus" @click="updatePrice('-')">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z" fill="black"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="auction-bids__col">
              <button @click="buyAuction" :disabled="bidButtonDisabledAmount" class="auction-bids__btn bid">{{ $t("main.bid") }}</button>
            </div>
          </div>
          <div v-if="isConnected && !auctionFinished" v-bind:class="[isIncreaseBid ? 'auction-bids__body active' : 'auction-bids__body']">
            <div class="auction-bids__col">
              <span>{{ $t("main.rank") }}</span>
              <div class="auction-bids__counter rank">
                <span class="num">№</span>
                <input type="number" @input="rank = rank.replace(/[^0-9]/g,''), rankCalculation()" v-model="rank"  placeholder="1">
                <button class="plus" @click="updateRank('+')">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.899555 4.7329C0.896889 4.8368 0.932029 4.93883 1.00014 5.02481C1.06826 5.11079 1.16598 5.17644 1.27964 5.21264C1.39329 5.24883 1.51727 5.25378 1.63435 5.22678C1.75144 5.19978 1.85575 5.14219 1.93289 5.062L4.99082 2.00243L8.04768 5.062C8.09525 5.11747 8.15563 5.16392 8.22511 5.19845C8.29459 5.23298 8.37166 5.25485 8.45145 5.26267C8.53124 5.2705 8.61204 5.26411 8.68882 5.24393C8.7656 5.22374 8.83672 5.19018 8.89767 5.14535C8.95862 5.10053 9.00806 5.04539 9.043 4.98341C9.07794 4.92143 9.09756 4.85393 9.10066 4.78514C9.10376 4.71635 9.0903 4.64776 9.06103 4.58365C9.03177 4.51954 8.98738 4.46131 8.93064 4.41259L5.43402 0.909657C5.37925 0.854648 5.31127 0.810475 5.23466 0.780186C5.15804 0.749896 5.07465 0.734211 4.99028 0.734211C4.90591 0.734211 4.82253 0.749896 4.74591 0.780186C4.6693 0.810475 4.60126 0.854648 4.54649 0.909657L1.0465 4.41259C0.954802 4.50108 0.902669 4.61458 0.899421 4.7329L0.899555 4.7329Z" fill="black"/>
                  </svg>
                </button>
                <button class="minus" @click="updateRank('-')">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z" fill="black"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="auction-bids__col">
              <span>{{ $t("main.price") }}</span>
              <div class="auction-bids__counter price">
                <input type="number" step="1" @input="changePrice" min="1"  v-model="price" placeholder="">
                <span class="price">xtz</span>
                <button class="plus" @click="updatePrice('+')">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.899555 4.7329C0.896889 4.8368 0.932029 4.93883 1.00014 5.02481C1.06826 5.11079 1.16598 5.17644 1.27964 5.21264C1.39329 5.24883 1.51727 5.25378 1.63435 5.22678C1.75144 5.19978 1.85575 5.14219 1.93289 5.062L4.99082 2.00243L8.04768 5.062C8.09525 5.11747 8.15563 5.16392 8.22511 5.19845C8.29459 5.23298 8.37166 5.25485 8.45145 5.26267C8.53124 5.2705 8.61204 5.26411 8.68882 5.24393C8.7656 5.22374 8.83672 5.19018 8.89767 5.14535C8.95862 5.10053 9.00806 5.04539 9.043 4.98341C9.07794 4.92143 9.09756 4.85393 9.10066 4.78514C9.10376 4.71635 9.0903 4.64776 9.06103 4.58365C9.03177 4.51954 8.98738 4.46131 8.93064 4.41259L5.43402 0.909657C5.37925 0.854648 5.31127 0.810475 5.23466 0.780186C5.15804 0.749896 5.07465 0.734211 4.99028 0.734211C4.90591 0.734211 4.82253 0.749896 4.74591 0.780186C4.6693 0.810475 4.60126 0.854648 4.54649 0.909657L1.0465 4.41259C0.954802 4.50108 0.902669 4.61458 0.899421 4.7329L0.899555 4.7329Z" fill="black"/>
                  </svg>
                </button>
                <button class="minus" :disabled="disabledMinus" @click="updatePrice('-')">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.10044 1.2671C9.10311 1.1632 9.06797 1.06117 8.99986 0.975189C8.93174 0.889211 8.83402 0.82356 8.72036 0.787364C8.60671 0.751167 8.48273 0.746224 8.36565 0.77322C8.24856 0.800217 8.14425 0.857812 8.06711 0.937998L5.00918 3.99757L1.95232 0.937998C1.90475 0.882529 1.84437 0.836075 1.77489 0.801545C1.70541 0.767015 1.62834 0.745154 1.54855 0.737329C1.46876 0.729504 1.38796 0.735885 1.31118 0.756072C1.2344 0.77626 1.16328 0.809818 1.10233 0.854646C1.04138 0.899473 0.991941 0.954605 0.957001 1.01659C0.92206 1.07857 0.902435 1.14607 0.89934 1.21486C0.896243 1.28365 0.909704 1.35224 0.938969 1.41635C0.968233 1.48046 1.01262 1.53869 1.06936 1.58741L4.56598 5.09034C4.62075 5.14535 4.68873 5.18952 4.76534 5.21981C4.84196 5.2501 4.92534 5.26579 5.00972 5.26579C5.09409 5.26579 5.17747 5.2501 5.25409 5.21981C5.3307 5.18952 5.39874 5.14535 5.45351 5.09034L8.9535 1.58741C9.0452 1.49892 9.09733 1.38542 9.10058 1.2671L9.10044 1.2671Z" fill="black"/>
                  </svg>
                </button>
              </div>
            </div>
            <div class="auction-bids__col" >
              <span>{{ $t("main.totalPrice") }}</span>
              <div class="auction-bids__counter total-price">
                <input type="number" readonly v-model="totalPriceNew" placeholder="">
                <span class="price">xtz</span>
              </div>
            </div>
            <div class="auction-bids__col">
              <button class="auction-bids__btn bid" @click="buyAuction" :disabled="bidButtonDisabled">{{ $t("main.bid") }}</button>
            </div>
          </div>
          <div v-if="!isConnected" class="auction-bids__alert">
            <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.6159 15.042L13.3582 11.1902C13.3078 10.4152 13.0434 9.16265 13.6353 8.53297C14.0857 8.04859 15.1523 7.96528 15.4759 8.63662C15.751 9.42551 15.8119 10.2733 15.6522 11.0934L15.3073 15.0585C15.2925 15.4317 15.2108 15.7992 15.0661 16.1435C15.0092 16.2549 14.9228 16.3486 14.8164 16.4145C14.71 16.4803 14.5876 16.5158 14.4625 16.5171C14.3374 16.5184 14.2143 16.4855 14.1066 16.4218C13.9988 16.3582 13.9106 16.2663 13.8513 16.1561C13.715 15.7993 13.6355 15.4234 13.6159 15.042ZM14.5081 20.3343C14.2026 20.3326 13.9093 20.2145 13.6877 20.0042C13.4662 19.7938 13.3331 19.507 13.3156 19.202C13.2981 18.897 13.3974 18.5968 13.5934 18.3624C13.7894 18.1281 14.0673 17.9772 14.3705 17.9405C14.5335 17.9208 14.6987 17.9347 14.8561 17.9813C15.0135 18.0279 15.1596 18.1063 15.2855 18.2116C15.4114 18.3169 15.5144 18.4468 15.5881 18.5935C15.6618 18.7401 15.7047 18.9003 15.7141 19.0641C15.7235 19.228 15.6993 19.392 15.6428 19.5461C15.5864 19.7003 15.499 19.8412 15.386 19.9602C15.273 20.0792 15.1368 20.1738 14.9858 20.2381C14.8348 20.3025 14.6722 20.3352 14.5081 20.3343Z" fill="#333333"/>
              <path d="M25.3433 24.1252H3.65682C3.1473 24.1173 2.64862 23.9769 2.20992 23.7176C1.77123 23.4583 1.40768 23.0892 1.1551 22.6466C0.902519 22.204 0.76963 21.7033 0.769531 21.1937C0.769433 20.6841 0.902129 20.1833 1.15454 19.7406L12.0104 2.33896C12.2741 1.91505 12.6417 1.56549 13.0783 1.3234C13.5149 1.0813 14.0061 0.954698 14.5054 0.955571C15.0046 0.956444 15.4954 1.08477 15.9312 1.32839C16.3669 1.572 16.7333 1.92285 16.9955 2.34768L27.8339 19.7203C28.0906 20.1627 28.2271 20.6645 28.2299 21.176C28.2327 21.6874 28.1018 22.1907 27.85 22.636C27.5983 23.0812 27.2345 23.4529 26.7948 23.7141C26.355 23.9753 25.8547 24.117 25.3433 24.1252ZM13.6659 3.34452L2.8111 20.7462C2.73105 20.894 2.6905 21.0599 2.69337 21.2279C2.69625 21.396 2.74244 21.5604 2.82749 21.7054C2.91253 21.8504 3.03356 21.9709 3.17884 22.0554C3.32413 22.1399 3.48877 22.1855 3.65682 22.1877H25.3433C25.5131 22.1851 25.6794 22.1383 25.8257 22.0518C25.972 21.9654 26.0932 21.8423 26.1774 21.6947C26.2616 21.5472 26.3059 21.3802 26.3059 21.2103C26.3059 21.0404 26.2616 20.8734 26.1774 20.7258C26.1774 20.7258 15.339 3.35033 15.3341 3.34452C15.2449 3.20408 15.1217 3.08844 14.9759 3.00832C14.8301 2.9282 14.6664 2.88619 14.5 2.88619C14.3337 2.88619 14.17 2.9282 14.0242 3.00832C13.8784 3.08844 13.7551 3.20408 13.6659 3.34452Z" fill="#333333"/>
            </svg>
            <span>{{ $t("main.please") }}, <a href="#" @click.prevent="plugInWallet">{{ $t("main.connect_wallet") }}</a> {{ $t("main.to_make_bid") }} </span>
          </div>
          <div v-if="auctionFinished && isConnected" class="auction-bids__finished">
<!--            <img src="../../public/closed.webp" alt="1 YEAR Auction has ended" class="auction-bids__image">-->
            <div class="auction-bids__closed" >
              <div>{{currentAuction}} YEAR Auction has ended</div>
              <p>You can see NFT’s in the Explorer </p>
              <router-link :to="{name: 'Explore'}" >Explore NFTs</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="auction-image">
        <img :src="require(`../../public/Lae-backup/pics/${imageId}.png`)" alt="1 YEAR Auction">
      </div>
      <div class="auction-list__outer">
        <div class="auction-list__bids logged">
          <div>
            <label v-if="!isIncreaseBid" class="auction-list__my" :class="{ checked: isMyBidsChecked }">
              {{$t('main.my_bids')}}
              <input type="checkbox" :checked="isMyBidsChecked" @change="toggleMyBids(false)">
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
        <div class="auction-list" :class="{ checked: isMyBidsChecked }">
          <div class="auction-list__header">
            <div class="auction-list__col">{{ $t("table.rank") }}</div>
            <div class="auction-list__col">{{ $t("table.bid") }}</div>
            <div class="auction-list__col">{{ $t("table.date") }}</div>
            <div class="auction-list__col">{{ $t("table.season") }}</div>
            <div class="auction-list__col">{{ $t("table.address") }}</div>
            <div class="auction-list__bids" :class="isConnected ? 'logged' : ''">
              <label v-if="!isIncreaseBid" class="auction-list__my">
                {{ $t("main.my_bids") }}
                <input type="checkbox" :checked="isMyBidsChecked" @change="toggleMyBids(false)">
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="auction-list__items">
            <div class="auction-list__item" :class="activeAuctionItem === transaction.key ? 'auction-list-item-active' : ''" v-for="(transaction, index) in transactions" :key="index" @click="isIncreaseBid ? updateAmount(transaction) : ''">
              <div class="auction-list__col" :class="!transaction.statusWin ? 'fault' : 'active'"><p>{{ $t("table.rank") }}</p>{{transaction.rank}}
<!--                <span v-if="transaction.rank" >( {{rankConverter(transaction)}} )</span>-->
              </div>
              <div class="auction-list__col" :title="transaction.value.amount / 1000000 + 'xtz'"><p>{{ $t("table.bid") }}</p>{{ transaction.value.amount / 1000000}} xtz</div>
              <div class="auction-list__col"><p>{{ $t("table.date") }}</p>{{ transaction.value.ts | moment("from", "now")  }}</div>
              <div class="auction-list__col"><p>{{ $t("table.season") }}</p>{{seasonConverter(transaction.value.season)}}</div>
              <div class="auction-list__col">{{transaction.value.bidder.substr(0,3)}}...{{transaction.value.bidder.substr(transaction.value.bidder.length - 4)}}</div>
<!--              <div class="auction-list__col">{{transaction.key.substr(0,3)}}...{{transaction.key.substr(transaction.key.length - 4)}}</div>-->
            </div>
          </div>
        </div>
      </div>
      <div class="auction-history" :class="!isConnected ? 'not-logged' : ''"> <!--not-logged class if wallet not connected-->
        <h3 class="auction-info__title">{{ $t("main.bids_history") }}</h3>
        <div class="auction-history__cont">
          <div class="auction-history__scroll">
            <div class="auction-history__alert" v-if="!isConnected">
              <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.6159 15.042L13.3582 11.1902C13.3078 10.4152 13.0434 9.16265 13.6353 8.53297C14.0857 8.04859 15.1523 7.96528 15.4759 8.63662C15.751 9.42551 15.8119 10.2733 15.6522 11.0934L15.3073 15.0585C15.2925 15.4317 15.2108 15.7992 15.0661 16.1435C15.0092 16.2549 14.9228 16.3486 14.8164 16.4145C14.71 16.4803 14.5876 16.5158 14.4625 16.5171C14.3374 16.5184 14.2143 16.4855 14.1066 16.4218C13.9988 16.3582 13.9106 16.2663 13.8513 16.1561C13.715 15.7993 13.6355 15.4234 13.6159 15.042ZM14.5081 20.3343C14.2026 20.3326 13.9093 20.2145 13.6877 20.0042C13.4662 19.7938 13.3331 19.507 13.3156 19.202C13.2981 18.897 13.3974 18.5968 13.5934 18.3624C13.7894 18.1281 14.0673 17.9772 14.3705 17.9405C14.5335 17.9208 14.6987 17.9347 14.8561 17.9813C15.0135 18.0279 15.1596 18.1063 15.2855 18.2116C15.4114 18.3169 15.5144 18.4468 15.5881 18.5935C15.6618 18.7401 15.7047 18.9003 15.7141 19.0641C15.7235 19.228 15.6993 19.392 15.6428 19.5461C15.5864 19.7003 15.499 19.8412 15.386 19.9602C15.273 20.0792 15.1368 20.1738 14.9858 20.2381C14.8348 20.3025 14.6722 20.3352 14.5081 20.3343Z" fill="#333333"/>
                <path d="M25.3433 24.1252H3.65682C3.1473 24.1173 2.64862 23.9769 2.20992 23.7176C1.77123 23.4583 1.40768 23.0892 1.1551 22.6466C0.902519 22.204 0.76963 21.7033 0.769531 21.1937C0.769433 20.6841 0.902129 20.1833 1.15454 19.7406L12.0104 2.33896C12.2741 1.91505 12.6417 1.56549 13.0783 1.3234C13.5149 1.0813 14.0061 0.954698 14.5054 0.955571C15.0046 0.956444 15.4954 1.08477 15.9312 1.32839C16.3669 1.572 16.7333 1.92285 16.9955 2.34768L27.8339 19.7203C28.0906 20.1627 28.2271 20.6645 28.2299 21.176C28.2327 21.6874 28.1018 22.1907 27.85 22.636C27.5983 23.0812 27.2345 23.4529 26.7948 23.7141C26.355 23.9753 25.8547 24.117 25.3433 24.1252ZM13.6659 3.34452L2.8111 20.7462C2.73105 20.894 2.6905 21.0599 2.69337 21.2279C2.69625 21.396 2.74244 21.5604 2.82749 21.7054C2.91253 21.8504 3.03356 21.9709 3.17884 22.0554C3.32413 22.1399 3.48877 22.1855 3.65682 22.1877H25.3433C25.5131 22.1851 25.6794 22.1383 25.8257 22.0518C25.972 21.9654 26.0932 21.8423 26.1774 21.6947C26.2616 21.5472 26.3059 21.3802 26.3059 21.2103C26.3059 21.0404 26.2616 20.8734 26.1774 20.7258C26.1774 20.7258 15.339 3.35033 15.3341 3.34452C15.2449 3.20408 15.1217 3.08844 14.9759 3.00832C14.8301 2.9282 14.6664 2.88619 14.5 2.88619C14.3337 2.88619 14.17 2.9282 14.0242 3.00832C13.8784 3.08844 13.7551 3.20408 13.6659 3.34452Z" fill="#333333"/>
              </svg>
              <span>{{ $t("main.please") }}, <a href="#" @click.prevent="plugInWallet">{{ $t("main.connect_wallet") }}</a> {{ $t("main.to_make_bid") }} </span>
            </div>
            <div class="auction-history__header" v-if="isConnected">
              <div class="auction-history__col">{{ $t("table.date") }}</div>
              <div class="auction-history__col">{{ $t("table.auction") }}</div>
              <div class="auction-history__col">{{ $t("table.season") }}</div>
              <div class="auction-history__col">{{ $t("table.rank") }}</div>
              <div class="auction-history__col">{{ $t("table.bid") }}</div>
              <div class="auction-history__col">{{ $t("table.address") }}</div>
              <div class="auction-history__col"></div>
              <div class="auction-history__col"></div>
            </div>
            <div class="auction-history__items" v-if="isConnected">
               <div v-for="(transaction, index) in userTransactions" :key="index" class="auction-history__item">
                 <div class="auction-history__col">{{dateConverter(transaction.value.ts)}}</div>
                 <div class="auction-history__col">{{transaction.year}} {{$t("table.year")}}</div>
                 <div class="auction-history__col"><p>Season</p>{{seasonConverter(transaction.value.season)}}</div>
                 <div class="auction-history__col"><p>Rank</p>{{transaction.rank}}</div>
                 <div class="auction-history__col"><p>Bid</p>{{ transaction.value.amount / 1000000}} xtz</div>
                 <div class="auction-history__col " >{{transaction.value.bidder.substr(0,3)}}...{{transaction.value.bidder.substr(transaction.value.bidder.length - 4)}}</div>
                 <div class="auction-history__col">
                     <button class="auction-history__btn increase" v-if="transaction.active && !transaction.statusWin && !auctionFinished " @click="increaseBid(), updateAmount(transaction)">Increase</button>
                 </div>
                 <div class="auction-history__col">
                     <button class="auction-history__btn withdraw" v-if="transaction.active && !transaction.statusWin" @click="openModal(transaction)">Withdraw</button>
                     <button class="auction-history__btn increase" v-if="transaction.statusWin && transaction.active && auctionFinished " @click="takeNft(transaction)">Take nft</button>
                 </div>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Faq />
  <WithdrawalModal :data="userData" :currentAuctionContract="currentAuctionContract" v-if="isModalOpen" :close="(status) => close(status)" />
  <div v-bind:class="[isBackdrop ? 'backdrop open' : 'backdrop']" @click="closeBackdrop"></div>
  </div>
</template>
<script>
import AuctionsHeader from '../components/AuctionsHeader.vue'
import WithdrawalModal from '../components/WithdrawModal.vue';
import Faq from '../components/Faq.vue'
import Timer from '../components/Timer.vue'
import date from '../config/date.js'
import { mapActions, mapState } from 'vuex'
import moment from "moment"
import config from '../config'
import AuctionLoading from "../components/Loading/AuctionLoading";

export default {
  name: 'Auction',
  components: {AuctionLoading, AuctionsHeader, Timer, Faq, WithdrawalModal},
  created() {
    let years = this.$route.params.years;
    this.currentAuction = +years;
    this.currentAuctionContract = config['contract_' + years + 'years'];
    this.auctionDate = date['date_' + years + 'years'];
    this.winCount = config['win_count_' + this.currentAuction + 'years']
    this.getCurrentPlaceTransactions(years);
    this.getStorage({contract: this.currentAuctionContract}).then((res) => {
      this.storage = res
      this.bidStep = +res.bid_step / this.timesMore
    });
    this.getSeason()

    if (years === '100') {
      this.imageId = this.seasonNumber
    } else if (years === '10') {
      this.imageId = this.seasonNumber  + '0'
      this.update10YearImage()
    } else {
      this.imageId = this.seasonNumber + '00'
      this.update1YearImage()
    }

  },
  data () {
    return {
      imageId: null,
      setTimeout1Year: null,
      setTimeout10Year: null,
      seasonNumber: 1,
      isOdd: false,
      isMaxWinners: true,
      bidStep: '',
      winCount: '',
      userData: '',
      rank: 1,
      currentAuctionContract: null,
      placeTransactions: [],
      auctionDate: null,
      activeAuctionItem: null,
      placeAmount: null,
      price: 2,
      totalPrice: 0,
      totalPriceNew: 0,
      transactionAmount: 0,
      placeNumber: 100,
      timesMore: 1000000,
      storage: {},
      transactionsData: [],
      currentAuction: 1,
      isMyBidsChecked: false,
      isModalOpen: false,
      isBackdrop: false,
      isMakeBid: true,
      bidButtonDisabled: true,
      isIncreaseBid: false,
      bidButtonDisabledAmount: false,
      auctionFinished: false,
      disabledMinus: false,
      loading: true
    };
  },
  computed: {
    ...mapState({
      userAddress: state => state.authWallet.userAddress,
      isConnected: state => state.authWallet.isConnected,
      transactions: state => state.auction.transactions,
      userTransactions: state => state.auction.userTransactions,
    }),
  },
  mounted () {
    this.getCurrentAuctionTransactions();
    this.getCurrentAuctionUserTransactions();
  },
  destroyed () {
    if (this.setTimeout1Year) {
      clearInterval(this.setTimeout1Year)
    }
    if (this.setTimeout10Year) {
      clearInterval(this.setTimeout10Year)
    }
  },
  methods: {
    ...mapActions({
      connectWallet: 'authWallet/connectWallet',
      startAuction: 'authWallet/startAuction',
      getStorage: 'auction/getStorage',
      getTransactions: 'auction/getTransactions',
      updateTransactions: 'authWallet/updateTransactions',
      getUserTransactions: 'auction/getUserTransactions',
      getPlaceTransactions: 'auction/getPlaceTransactions',
      takeNftResolve: 'authWallet/takeNftResolve'
    }),
    getSeason() {
      let nowDate = moment().format("M");
      if (nowDate === '12' || nowDate === '1' || nowDate === '2') {
        this.seasonNumber = 1
      } else if (nowDate === '3' || nowDate === '4' || nowDate === '5') {
        this.seasonNumber = 2
      } else if (nowDate === '6' || nowDate === '7' || nowDate === '8') {
        this.seasonNumber = 3
      } else {
        this.seasonNumber = 4
      }
    },
    update1YearImage() {
      this.setTimeout1Year = setInterval(() => {
        let random = Math.floor(Math.random() * 100)
        if (random) {
          if (random < 10) {
            random = '0' + random
          }
          this.imageId = this.seasonNumber+ '' + random
        }
      }, 5000)
    },
    update10YearImage() {
      this.setTimeout10Year = setInterval(() => {
        const random = Math.floor(Math.random() * 10)
        if (random) {
          this.imageId = this.seasonNumber+ '' + random
        }
      }, 5000)
    },
    async plugInWallet () {
      const res = await this.connectWallet();
      this.isLogged = !!res;
    },
    takeNft (data) {
      this.takeNftResolve({contract: this.currentAuctionContract, id: data.key, amount: data.value.amount}).then(() => {})
    },
    updateAmount(transaction) {
      if (this.activeAuctionItem === transaction.key) {
        this.activeAuctionItem = false
        this.bidButtonDisabled = true
        this.price = 0
      } else {
        let price = 1
        if (this.transactionsData[this.winCount - 1]) {
          const lastWin = this.transactionsData[this.winCount - 1]
          price = (lastWin.value.amount / 1000000) - (transaction.value.amount / this.timesMore) + this.bidStep
        }
        this.bidButtonDisabled = false
        this.activeAuctionItem = transaction.key
        this.price = price
        this.transactionAmount = transaction.value.amount
        this.totalPrice = (transaction.value.amount / this.timesMore)
        this.totalPriceNew = (transaction.value.amount / this.timesMore) + price
        this.priceCalculation()
      }
    },
    seasonConverter (season) {
      if (season === '1') {
        return 'Winter'
      } else if (season === '2') {
        return 'Spring'
      } else if (season === '3') {
        return 'Summer'
      } else {
        return 'Autumn'
      }
    },
    rankConverter (transaction) {
      if (transaction.type === 'minus') {
        if (transaction.rank > 0) {
          return '-' + '  ' +  transaction.rank
        } else {
          return  transaction.rank
        }
      } else {
        return '+' + transaction.rank
      }
    },
    getCurrentAuctionTransactions(isMyBidsChecked = false, myBids = true) {
      let id = null
      if (this.currentAuction === 1) {
        id = localStorage.getItem('1yearsBigMapId')
      } else if (this.currentAuction === 10) {
        id = localStorage.getItem('10yearsBigMapId')
      } else {
        id = localStorage.getItem('100yearsBigMapId')
      }
      let params = {};
      if (isMyBidsChecked) {
        params['id'] = id
        params['sender'] = this.userAddress
        params['contract'] = this.currentAuctionContract
        params['myBids'] = myBids
      } else {
        params['target'] = this.currentAuctionContract
      }
      this.getTransactions(params).then(() => {
        if (!isMyBidsChecked) {
          this.transactionsData = this.transactions
          if (this.transactionsData[this.winCount - 1]) {
            const lastWinAmount = this.transactionsData[this.winCount - 1].value.amount / this.timesMore
            if (lastWinAmount % 2 === 0) {
              this.isOdd = false
            } else {
              this.isOdd = true
            }
          }
        }
        if (!this.isIncreaseBid) {
          this.rank = 1
          this.rankCalculation()
        }
      });
    },
    getCurrentPlaceTransactions(years) {
      let params = {
        target: this.currentAuctionContract
      };
      this.getPlaceTransactions(params).then((res) => {
        this.placeTransactions = res
        if (years === '1') {
          this.placeNumber = 100
          if (res && res[99]) {
            this.placeAmount = (+res[99].value.amount / this.timesMore ) + ' xtz'
          } else {
            this.placeAmount = '---'
          }
        } else if (years === '10') {
          this.placeNumber = 10
          if (res && res[9]) {
            this.placeAmount = (+res[9].value.amount / this.timesMore ) + ' xtz'
          } else {
            this.placeAmount = '---'
          }
        } else if (years === '100') {
          this.placeNumber = 1
          if (res && res[0]) {
            this.placeAmount = (+res[0].value.amount / this.timesMore ) + ' xtz'
          } else {
            this.placeAmount = '---'
          }
        }
      });
    },
    getCurrentAuctionUserTransactions() {
      if (this.userAddress){
        this.loading = true
        let id = null
        if (this.currentAuction === 1) {
          id = localStorage.getItem('1yearsBigMapId')
        } else if (this.currentAuction === 10) {
          id = localStorage.getItem('10yearsBigMapId')
        } else {
          id = localStorage.getItem('100yearsBigMapId')
        }
        this.getUserTransactions({
          sender: this.userAddress,
          id: id,
          contract: this.currentAuctionContract
        }).finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 1000)
        })
      } else {
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }
    },
    buyAuction () {
      if (this.isIncreaseBid && !this.bidButtonDisabled) {
        this.updateTransactions({amount: this.price, id: this.activeAuctionItem, contract: this.currentAuctionContract})
      } else {
        this.startAuction({ amount: this.price, auction: this.currentAuction})
      }
    },
    dateConverter (date) {
      return moment(date).format('l LT')
    },
    rankCalculation () {
      this.disabledMinus = false
      this.rank = +this.rank
      if (this.isIncreaseBid) {
        const oldPrice = this.totalPriceNew
        this.placeTransactions.map((item, index) => {
          if (this.rank === 1) {
            this.totalPriceNew = (this.placeTransactions[0].value.amount / this.timesMore) + this.bidStep
          } else {
            if (this.rank === index + 1 && index !== 0) {
              const price = (this.placeTransactions[index - 1].value.amount / this.timesMore) - this.bidStep
              this.totalPriceNew = Math.ceil(price)
              this.totalPriceNew = this.totalPriceNew + this.bidStep
            } else if (this.rank > this.placeTransactions.length) {
              this.totalPriceNew =  (this.placeTransactions[this.placeTransactions.length - 1].value.amount / this.timesMore )
            }
          }
        })
        if (this.transactionsData[this.winCount - 1]) {
          const lastWin = this.transactionsData[this.winCount - 1]
          if ((this.totalPriceNew) <= (lastWin.value.amount / 1000000)) {
            this.rank--
            this.totalPriceNew = oldPrice
          }
        }
        this.price = +this.totalPriceNew - +this.totalPrice
      } else {
        if (this.placeTransactions.length) {
          const oldPrice = this.price
          this.placeTransactions.map((item, index) => {
            if (this.rank === 1) {
              this.price = (this.placeTransactions[0].value.amount / this.timesMore) + this.bidStep
            } else {
              if (this.rank === index + 1 && index !== 0) {
                const price = (this.placeTransactions[index - 1].value.amount / this.timesMore) - this.bidStep
                this.price = Math.ceil(price)
                this.price = this.price + this.bidStep
              } else if (this.rank > this.placeTransactions.length) {
                this.price =  (this.placeTransactions[this.placeTransactions.length - 1].value.amount / this.timesMore )
              }
            }
          })
          if (this.transactionsData[this.winCount - 1]) {
            const lastWin = this.transactionsData[this.winCount - 1]
            if ((this.price) <= (lastWin.value.amount / 1000000)) {
              this.rank--
              this.price = oldPrice
            }
          }
        } else {
          this.price = this.bidStep
        }
      }
    },
    changePrice() {
      this.priceCalculation()
      if (+this.price) {
        this.price = this.price.replace(/[^0-9]/g,'')
      } else {
        this.price = 1
      }
      if (this.transactionsData[this.winCount - 1]) {
        const lastWin = this.transactionsData[this.winCount - 1]
        if ((this.price - 1) <= (lastWin.value.amount / 1000000)) {
          this.price = (lastWin.value.amount / 1000000) + 1
        }
      }
    },
    priceCalculation () {
      this.disabledMinus = false
      let price = +this.price
      if (this.isIncreaseBid) {
        this.totalPriceNew = +this.totalPrice + +this.price
        price = this.totalPriceNew
      }
      this.placeTransactions.map((item, index) => {
        if (index === 0) {
          if (price > (item.value.amount / 1000000)) {
            this.rank = 1
            this.bidButtonDisabledAmount = false
            this.bidButtonDisabled = false
          } else if (price === (item.value.amount / 1000000)) {
            this.rank = this.rank + 1
            this.bidButtonDisabledAmount = false
            this.bidButtonDisabled = false
          }
        } else {
          if (price < (item.value.amount / 1000000)) {
            this.rank = this.placeTransactions.length + 1
          } else if (price > (item.value.amount / 1000000) && price <= (this.placeTransactions[index - 1].value.amount / 1000000)) {
            this.rank = index + 1
          }
        }
      })
    },
    updateRank (type) {
      if (type === '+') {
        if (this.isIncreaseBid) {
          if (this.totalPrice && (this.totalPrice + 1) < this.totalPriceNew) {
            if (this.rank <= this.placeTransactions.length) {
              this.rank++
              this.rankCalculation()
            }
          }
        } else {
          if (this.rank <= this.placeTransactions.length) {
            this.rank++
            this.rankCalculation()
          }
        }
      } else {
        if (this.rank > 1) {
          this.rank --
          this.rankCalculation()
        }
      }
    },
    updatePrice (type) {
      if (type === '+') {
        this.price += this.bidStep
        this.priceCalculation()
      } else {
        if (this.price > this.bidStep) {
          if (this.isIncreaseBid) {
            if (this.transactionsData[this.winCount - 1]) {
              const lastWin = this.transactionsData[this.winCount - 1]
              if ((+this.totalPriceNew - 1) <= (lastWin.value.amount / 1000000) + this.bidStep) {
                this.disabledMinus = true
              } else {
                this.price -= this.bidStep
                this.priceCalculation()
              }
            } else {
              this.price -= this.bidStep
              this.priceCalculation()
            }
          } else {
            if (this.transactionsData[this.winCount - 1]) {
              const lastWin = this.transactionsData[this.winCount - 1]
              if ((this.price - 1) <= (lastWin.value.amount / 1000000) + this.bidStep) {
                this.disabledMinus = true
              } else {
                this.price -= this.bidStep
                this.priceCalculation()
              }
            } else {
              this.price -= this.bidStep
              this.priceCalculation()
            }
          }
        }
      }
    },
    close (status) {
      this.isModalOpen = false;
      console.log(status)
      // if (status) {
      //   this.getCurrentAuctionTransactions();
      //   this.getCurrentAuctionUserTransactions();
      // }
    },
    closeBackdrop () {
      if (this.isMyBidsChecked) {
        this.toggleMyBids()
      }
      this.activeAuctionItem = null
      this.isBackdrop = false;
      this.isMakeBid = true;
      this.isIncreaseBid = false;
      this.bidButtonDisabled = true
    },
    openModal (data) {
      this.userData = data
      this.isModalOpen = true;
    },
    toggleMyBids(myBids = true) {
      this.getCurrentAuctionTransactions(!this.isMyBidsChecked, myBids);
      this.isMyBidsChecked = !this.isMyBidsChecked;
    },
    makeBid () {
      if (this.isMyBidsChecked) {
        this.toggleMyBids()
      }
      this.activeAuctionItem = null
      this.isMakeBid = true;
      this.isIncreaseBid = false;
      this.bidButtonDisabled = true
      this.isBackdrop = false;
    },
    increaseBid () {
      if (!this.isMyBidsChecked) {
        this.toggleMyBids()
      }
      this.rank = null
      this.totalPriceNew = null
      this.price = null
      this.isMakeBid = false;
      this.isIncreaseBid = true;
      this.bidButtonDisabled = true
      this.isBackdrop = true;
      document.getElementById('bids').scrollIntoView({ block: 'start',  behavior: 'smooth' });
    },
    isAuctionFinished (res) {
      if (!res) {
        this.auctionFinished = false
      } else {
        this.auctionFinished = true
      }
    }
  },
  watch: {
    $route (to) {
      if (to.name === 'Auction') {
        let years = to.params.years;
        this.currentAuction = +to.params.years;
        this.currentAuctionContract = config['contract_' + years + 'years'];
        this.auctionDate = date['date_' + years + 'years'];
        this.auctionFinished = false
        this.getCurrentAuctionTransactions();
        this.getCurrentAuctionUserTransactions();
        this.getCurrentPlaceTransactions(years);
        this.getStorage({contract: this.currentAuctionContract}).then((res) => {
          this.storage = res
          this.bidStep = +res.bid_step / this.timesMore
        });

        if (years === '100') {
          this.imageId = this.seasonNumber
          if (this.setTimeout10Year) {
            clearInterval(this.setTimeout10Year)
          }
          if (this.setTimeout1Year) {
            clearInterval(this.setTimeout1Year)
          }
        } else if (years === '10') {
          this.imageId = this.seasonNumber  + '0'
          this.update10YearImage()
          if (this.setTimeout1Year) {
            clearInterval(this.setTimeout1Year)
          }
        } else {
          this.imageId = this.seasonNumber + '00'
          this.update1YearImage()
          if (this.setTimeout10Year) {
            clearInterval(this.setTimeout10Year)
          }
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">

  .results {
    display: block;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .auction {
    .ptb-16 {
      padding-top: 17px;
      padding-bottom: 17px;
    }
    .auction-list-item-active {
      @media (min-width: 768px) {
        padding: 10px 17px;
        background: rgba(237, 125, 55, 0.1);
        border: 1px solid #ED7D37 !important;
        border-radius: 5px;
        margin-left: 0;
        margin-right: -5px;
      }
      @media (min-width: 1225px) {
        padding: 10px 0 10px 17px;
      }
    }
    &:not(.header-info) {
      margin-top: 24px;
      margin-bottom: 56px;
      @media (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
    .wrapper {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 520px 1fr;
      @media (max-width: 1225px) {
        grid-template-columns: 480px 1fr;
      }
      @media (max-width: 1050px) {
        grid-template-columns: 400px 1fr;
      }
      @media (max-width: 992px) {
        grid-template-columns: minmax(100px, 1fr);;
        grid-gap: 16px;
      }
    }
    &-info {
      @media (max-width: 768px) {
        order: 1;
      }
      &__title {
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
        color: #333333;
        padding-left: 24px;
      }
      &__cont {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin-top: 12px;
        //height: 176px;
        height: 100%;
      }
      &__block {
        padding: 18px 24px;
        display: flex;
        align-items: center;
        @media (max-width: 1050px) {
          flex-wrap: wrap;
        }
        @media (max-width: 768px) {
          padding: 16px;
        }
        .flip-clock {
          margin-left: -10px;
        }
        &:first-child {
          border-bottom: 1px solid #DFDFDF;
        }
      }
      &__head {
        display: flex;
        max-width: 155px;
        width: 100%;
        @media (max-width: 1050px) {
          flex-basis: 100%;
          margin-bottom: 16px;
          max-width: 100%;
        }
        @media (max-width: 992px) {
          flex-basis: auto;
          margin-bottom: 0;
          max-width: 155px;
        }
        @media (max-width: 576px) {
          flex-basis: 100%;
          margin-bottom: 16px;
          max-width: 100%;
        }
      }
      &__name {
        margin-left: 10px;
        margin-right: 30px;
        font-size: 18px;
        line-height: 21px;
        color: #333333;
      }
      &__details {
        p {
          font-size: 18px;
          line-height: 21px;
          color: #333333;
          margin-bottom: 8px;
          white-space: nowrap;
        }
        span {
          font-size: 14px;
          line-height: 16px;
          color: #B1B1B1;
          white-space: nowrap;
        }
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
      &__over {
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #C2C2C2;
        width: 100%;
      }
    }
    &-image {
      width: 100%;
      height: 489px;
      //background: #7D888A;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      overflow: hidden;
      img {
        display: inherit;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media (max-width: 768px) {
        height: auto;
        order: 2;
      }
    }
    &-bids {
      height: 100%;
      @media (max-width: 768px) {
        order: 3;
      }
      &.not-logged {
        .auction-bids__cont {
          background: #F9F9F9;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.closed {
          .auction-bids__cont {
            background: #fff;
            justify-content: flex-start;
            height: 176px;
            @media (max-width: 1050px) {
              height: 210px;
            }
            @media (max-width: 992px) {
              padding: 20px;
            }
          }
        }
        .auction-bids__alert {
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            margin-left: 12px;
            font-size: 18px;
            line-height: 21px;
            color: #333333;
          }
        }
      }
      &__closed {
        div {
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          color: #333333;
        }
        p {
          margin: 10px 0;
          font-size: 16px;
          line-height: 26px;
          color: #333333;
        }
        a {
          display: inline-block;
          text-decoration: none;
          color: #ffffff;
          font-size: 18px;
          line-height: 21px;
          padding: 12px 16px;
          background: #ED7D37;
          border-radius: 5px;
          &:hover {
            background: #E07735;
          }
        }
      }
      &__cont {
        margin-top: 12px;
        height: 176px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        overflow: hidden;
        background: #fff;
        position: relative;
        z-index: 98;
        img {
          margin-left: -40px;
          margin-right: 26px;
          @media (max-width: 1225px) {
            width: 200px;
          }
          @media (max-width: 992px) {
            width: 100%;
            margin: 0;
            position: absolute;
            top: -50%;
            left: 0;
            opacity: .1;
            z-index: -1;
          }
        }
        @media (max-width: 1050px) {
          height: 250px;
        }
        @media (max-width: 992px) {
          height: 218px;
        }
      }
      &__header {
        display: flex;
        align-items: center;
        padding: 0 25px;
        border-bottom: 1px solid #DFDFDF;
      }
      &__prompt {
        margin-left: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        span {
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          display: inline-block;
          margin-right: 6px;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      &__tab {
        cursor: pointer;
        margin-right: 25px;
        font-size: 18px;
        line-height: 21px;
        color: #B1B1B1;
        border-bottom: 2px solid transparent;
        margin-bottom: -1.5px;
        padding: 14px 6px 12px;
        transition: .35s;
        &:hover {
          color: #333333;
        }
        &.active {
          color: #333333;
          border-bottom: 2px solid #ED7D37;
        }
        @media (max-width: 768px) {
          padding: 12px 6px 10px;
          white-space: nowrap;
          &:nth-child(2) {
            margin-right: 0;
          }
        }
      }
      &__body {
        transition: .35s;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 49px;
        //height: 127px;
        padding: 16px 30px;
        display: flex;
        align-items: flex-end;
        width: 100%;
        &.active {
          opacity: 1;
          visibility: visible;
        }
        @media (max-width: 1050px) {
          flex-wrap: wrap;
        }
        @media (max-width: 768px) {
          padding: 14px 12px;
          top: 45px;
        }
      }
      &__col {
        display: flex;
        flex-direction: column;
        &:not(:last-child) {
          margin-right: 14px;
        }
        &:nth-child(2) {
          @media (max-width: 576px) {
            margin-right: 0;
            flex-basis: calc(100% - 130px);
          }
        }
        span {
          display: block;
          font-size: 18px;
          line-height: 21px;
          color: #B1B1B1;
          margin-bottom: 10px;
        }
        &:last-child {
          @media (max-width: 576px) {
            flex-basis: 100%;
            margin-top: 12px;
          }
        }
      }
      &__counter {
        position: relative;
        background: #FFFFFF;
        border: 1px solid #DBDBDB;
        border-radius: 5px;
        transition: .35s;
        display: flex;
        align-items: center;
        height: 50px;
        cursor: pointer;
        &:hover {
          border: 1px solid #ED7D37;
        }
        span {
          color: #8B8B8B;
          margin-bottom: 0;
          &.num {
            margin-left: 12px;
          }
          &.price {
            margin-right: 42px;
            margin-left: 14px;
          }
        }
        &.rank {
          width: 114px;
        }
        &.total-price {
          width: auto;
          justify-content: space-between;
          span {
            margin-right: 5px;
          }
        }
        &.price {
          width: 188px;
          input {
            padding-left: 12px;
            padding-right: 0;
          }
        }
        input {
          width: calc(100% - 60px);
          outline: none;
          border: none;
          padding: 8px;
          font-size: 18px;
          line-height: 21px;
          color: #000000;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
        button {
          position: absolute;
          right: 9px;
          padding: 6px 7px;
          background: #F3F3F3;
          border-radius: 3px;
          display: flex;
          align-items: center;
          &:hover {
            background: #ED7D37;
            svg path {
              fill: #fff;
            }
          }
          &.plus {
            top: 5px;
          }
          &.minus {
            bottom: 5px;
          }
        }
      }
      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 100px;
        border-radius: 5px;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
        background: #ED7D37;
        &:disabled {
          background: #DFDFDF;
        }
        &:not(:disabled):hover {
          background: #E07735;
        }
        @media (max-width: 576px) {
          width: 100%;
        }
      }
      &__finished {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        img {
          margin-top: -30px;
        }
        div {
          margin-top: 18px;
        }
      }
      &__alert {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: inline-block;
          margin-left: 12px;
          font-size: 18px;
          line-height: 21px;
          color: #333333;
        }
        @media (max-width: 768px) {
          flex-direction: column;
          span {
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }
    }
    &-list {
      background: #FFFFFF;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      height: 100%;
      padding: 12px 20px 12px 15px;
      border: 1px solid transparent;
      position: relative;
      z-index: 98;
      @media (max-width: 768px) {
        padding: 12px;
      }
      &__outer {
        min-height: 100%;
        @media (max-width: 768px) {
          order: 4;
        }
        & > .auction-list__bids {
          display: none !important;
          @media (max-width: 1225px) {
            display: table !important;
          }
        }
      }
      &.checked {
        border: 1px solid #ED7D37;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
      }
      &__empty {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 18px;
        line-height: 21px;
        color: #B1B1B1;
        @media(max-width: 768px) {
          min-height: 220px;
        }
      }
      &__header {
        display: flex;
        //justify-content: space-between;
        padding-left: 17px;
        padding-bottom: 10px;
        border-bottom: 1px solid #DFDFDF;
        margin-left: 5px;
        .auction-list__col {
          color: #727A89;
        }
        @media (max-width: 1225px) {
          display: none;
        }
      }
      &__bids {
        margin-left: auto;
        display: none;
        &.logged {
          display: block;
        }
        @media (max-width: 1225px) {
          display: table;
          margin-right: 20px;
          padding: 5px 20px;
          border-radius: 10px 10px 0 0;
          background: #FFFFFF;
          box-shadow: 0px -3px 15px rgb(0 0 0 / 10%);
          position: relative;
          z-index: 99;
          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: -10%;
            width: 120%;
            height: 13px;
            background: #fff;
          }
        }
      }
      &__my {
        white-space: nowrap;
        display: block;
        position: relative;
        padding-left: 23px;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        line-height: 26px;
        color: #000000;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked {
            & ~ span {
              background-color: #ED7D37;
              border: 1px solid #ED7D37;
              &:after {
                opacity: 1;
              }
            }
          }
        }
        span {
          position: absolute;
          top: 5px;
          left: 0;
          height: 15px;
          width: 15px;
          background-color: #fff;
          border: 1px solid #C2C2C2;
          border-radius: 2px;
          transition: .35s;
          &::after {
            content: "";
            position: absolute;
            opacity: 0;
            transition: .35s;
            top: -1px;
            left: -1px;
            width: 15px;
            height: 15px;
            background: url("../../public/checkbox.svg") no-repeat center center;
          }
        }
        &:hover {
          input ~ span {
            background-color: #ED7D37;
            border: 1px solid #ED7D37;
            //&:after {
            //  opacity: 1;
            //}
          }
        }
      }
      &__col {
        font-size: 16px;
        line-height: 24px;
        color: #333333;
        position: relative;
        &:not(:last-child) {
          @media (max-width: 1225px) {
            margin-top: 10px;
          }
        }
        @media (max-width: 1225px) {
          display: flex;
          align-items: center;
        }
        p {
          font-size: 16px;
          line-height: 20px;
          color: #727A89;
          display: none;
          @media (max-width: 1225px) {
            display: block;
            margin-right: auto;
          }
        }
        span {
          position: absolute;
          top: 0;
          left: 30px;
          @media (max-width: 1225px) {
            position: static;
            margin-left: 8px;
          }
        }
        &:nth-child(1) {
          flex-basis: 100px;
          @media (max-width: 1225px) {
            flex-basis: auto;
          }
        }
        &:nth-child(2) {
          flex-basis: 120px;
          max-width: 120px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          @media (max-width: 1225px) {
            flex-basis: auto;
            max-width: 100%;
          }
        }
        &:nth-child(3) {
          flex-basis: 150px;
          @media (max-width: 1225px) {
            flex-basis: auto;
          }
        }
        &:nth-child(4) {
          flex-basis: 80px;
          @media (max-width: 1225px) {
            flex-basis: auto;
          }
        }
        &:nth-child(5) {
          @media (max-width: 1225px) {
            order: -1;
          }
        }
        &.active {
          color: #46C07C;
        }
        &.fault {
          color: #FF0000;
        }
        &.change {
          span {
            color: #ED7D37;
          }
        }
      }
      &__items {
        overflow-y: auto;
        max-height: 416px;
        height: 100%;
        margin-top: 10px;
        padding-right: 20px;
        margin-left: 5px;
        @media (max-width: 1225px) {
          margin: 0;
          max-height: 427px;
        }
        @media (max-width: 768px) {
          max-height: 500px;
        }
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 1px;
          background: #EAEAEA;
        }
        &::-webkit-scrollbar-thumb {
          background: #ED7D37;
          border-radius: 1px;
        }
      }
      &__item {
        display: flex;
        padding: 10px 0 10px 12px;
        margin-left: 5px;
        border: 1px solid transparent;
        cursor: pointer;
        transition: .35s;
        &:not(:first-child) {
          border-top: 1px solid #DFDFDF;
        }
        &:last-child {
          border-bottom: 1px solid #DFDFDF;
        }

        &:hover {
          @media (min-width: 768px) {
            padding: 10px 17px 10px 5px;
            background: rgba(237, 125, 55, 0.1);
            border: 1px solid #ED7D37;
            border-radius: 5px;
            margin-left: 5px;
            & + .auction-list__item {
              border-top: 1px solid transparent;
            }
          }
          @media (min-width: 1225px) {
            padding: 10px 0 10px 12px;
          }
        }
        @media (max-width: 1225px) {
          flex-direction: column;
          padding: 10px 12px;
        }
        @media (max-width: 768px) {
          margin-left: 0;
        }
      }
    }
    &-history {
      grid-column: span 2 / auto;
      @media (max-width: 992px) {
        grid-column: 1;
      }
      @media (max-width: 768px) {
        order: 5;
      }
      &.not-logged {
        .auction-history__cont {
          background: #F9F9F9;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 768px) {
            padding: 0;
          }
        }
        .auction-history__alert {
          display: flex;
          align-items: center;
          span {
            display: inline-block;
            margin-left: 12px;
            font-size: 18px;
            line-height: 21px;
            color: #333333;
          }
          @media (max-width: 768px) {
            flex-direction: column;
            span {
              margin-left: 0;
              margin-top: 12px;
            }
          }
        }
      }
      &__cont {
        margin-top: 20px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        width: 100%;
        height: 420px;
        background: #fff;
        padding: 12px 30px 10px;
        overflow-x: auto;
        overflow-y: visible;
        @media (max-width: 768px) {
          min-height: 150px;
          height: auto;
          padding: 0 7px 10px;
        }
        &::-webkit-scrollbar {
          height: 5px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 2.5px;
          background: #EAEAEA;
        }
        &::-webkit-scrollbar-thumb {
          background: #ED7D37;
          border-radius: 2.5px;
        }
      }
      &__scroll {
        min-width: 1140px;
        @media (max-width: 768px) {
          min-width: 1px;
        }
      }
      &__alert {
        width: 100%;
        height: calc(100% - 37px);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        line-height: 21px;
        color: #B1B1B1;
      }
      &__header {
        white-space: nowrap;
        display: flex;
        //justify-content: space-between;
        padding-left: 12px;
        padding-right: 20px;
        padding-bottom: 12px;
        border-bottom: 1px solid #DFDFDF;
        .auction-history__col {
          color: #727A89;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }
      &__col {
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        position: relative;
        white-space: nowrap;
        span {
          position: absolute;
          top: 0;
          left: 50px;
          @media (max-width: 768px) {
            position: static;
            margin-left: 8px;
          }
        }
        p {
          display: none;
          color: #727A89;
          margin-right: 10px;
          @media (max-width: 768px) {
            display: block;
          }
        }
        @media (max-width: 768px) {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 20px;
        }
        &:nth-child(1) {
          flex-basis: min(210px);
          @media (max-width: 768px) {
            order: 2;
            font-size: 14px;
            line-height: 20px;
            color: #727A89;
            margin-left: auto;
          }
        }
        &:nth-child(2) {
          flex-basis: min(120px);
          @media (max-width: 768px) {
            order: 1;
            font-weight: bold;
          }
        }
        &:nth-child(3) {
          flex-basis: min(120px);
          p {
            width: 55px;
          }
          @media (max-width: 768px) {
            order: 6;
          }
        }
        &:nth-child(4) {
          flex-basis: min(110px);
          p {
            width: 55px;
          }
          @media (max-width: 768px) {
            order: 4;
          }
        }
        &:nth-child(5) {
          flex-basis: min(180px);
          @media (max-width: 768px) {
            order: 5;
          }
        }
        &:nth-child(6) {
          flex-basis: min(120px);
          @media (max-width: 768px) {
            order: 3;
            grid-column: span 2 / auto;
          }
        }
        &:nth-child(7) {
          flex-basis: min(130px);
          @media (max-width: 768px) {
            order: 7;
            grid-column: span 2 / auto;
            justify-content: center;
          }
        }
        &:nth-child(8) {
          flex-basis: min(130px);
          @media (max-width: 768px) {
            order: 7;
            grid-column: span 2 / auto;
            justify-content: center;
          }
        }
        &:nth-child(9) {
          flex-basis: min(130px);
          @media (max-width: 768px) {
            order: 7;
            grid-column: span 2 / auto;
            justify-content: center;
          }
        }
        &.active {
          color: #46C07C;
        }
        &.fault {
          color: #FF0000;
        }
        &.change {
          span {
            color: #ED7D37;
          }
        }
      }
      &__items {
        overflow-y: auto;
        max-height: 346px;
        height: 100%;
        margin-top: 10px;
        padding-right: 20px;
        @media (max-width: 768px) {
          max-height: 500px;
        }
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 1px;
          background: #EAEAEA;
        }
        &::-webkit-scrollbar-thumb {
          background: #ED7D37;
          border-radius: 1px;
        }
        @media (max-width: 768px) {
          padding-right: 8px;
        }
      }
      &__item {
        white-space: nowrap;
        display: flex;
        align-items: center;
        padding: 0 0 0 12px;
        height: 52px;
        //border: 1px solid transparent;
        border-bottom: 1px solid #DFDFDF;
        cursor: default;
        @media (max-width: 768px) {
          height: auto;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 8px;
          padding: 12px 8px;
        }
      }
      &__btn {
        padding: 5px 16px;
        border-radius: 5px;
        font-size: 18px;
        line-height: 24px;
        transition: .35s;
        &.increase {
          background: rgba(237, 125, 55, 0.1);
          border-radius: 5px;
          border: 1px solid transparent;
          color: #ED7D37;
          margin-right: 25px;
          &:hover {
            border: 1px solid #ED7D37;
          }
        }
        &.withdraw {
          color: #333333;
          &:hover {
            color: #ED7D37;
          }
        }
      }
    }
  }
  .backdrop {
    background: rgba(0, 0, 0, 0.431372549);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 97;
    opacity: 0;
    visibility: hidden;
    transition: .35s;
    &.open {
      opacity: 1;
      visibility: visible;
    }
  }
</style>
