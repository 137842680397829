var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "preloader"
  }, [_c('header-loading'), _c('div', {
    staticClass: "flex loader-bg",
    staticStyle: {
      "justify-content": "center"
    }
  }, _vm._l(3, function (i) {
    return _c('vue-skeleton-loader', {
      key: i,
      staticClass: "mrl-35",
      attrs: {
        "type": "rect",
        "width": 195,
        "height": 51,
        "rounded": true,
        "radius": 20,
        "animation": "wave"
      }
    });
  }), 1), _c('div', {
    staticClass: "preloader-main"
  }, [_c('div', {
    staticClass: "preloader-main-left"
  }, [_c('vue-skeleton-loader', {
    staticClass: "mb-20",
    attrs: {
      "type": "rect",
      "width": 167,
      "height": 26,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "w-full mb-20",
    attrs: {
      "type": "rect",
      "height": 164,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "w-full mb-20",
    attrs: {
      "type": "rect",
      "height": 473,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  })], 1), _c('div', {
    staticClass: "preloader-main-right"
  }, [_c('vue-skeleton-loader', {
    staticClass: "mb-20",
    attrs: {
      "type": "rect",
      "width": 167,
      "height": 26,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "w-full mb-20",
    attrs: {
      "type": "rect",
      "height": 164,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  }), _c('vue-skeleton-loader', {
    staticClass: "w-full mb-20",
    attrs: {
      "type": "rect",
      "height": 473,
      "rounded": true,
      "radius": 5,
      "animation": "wave"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }