<template>
  <div class="preloader">
    <header-loading/>
    <div class="flex loader-bg" style="justify-content: center">
      <vue-skeleton-loader
        v-for="i in 3"
        :key="i"
        type="rect"
        :width="195"
        :height="51"
        :rounded="true"
        class="mrl-35"
        :radius="20"
        animation="wave"
      />
    </div>
    <div class="preloader-main">
      <div class="preloader-main-left">
        <vue-skeleton-loader
          type="rect"
          :width="167"
          :height="26"
          :rounded="true"
          class="mb-20"
          :radius="5"
          animation="wave"
        />
        <vue-skeleton-loader
          type="rect"
          :height="164"
          :rounded="true"
          class="w-full mb-20"
          :radius="5"
          animation="wave"
        />
        <vue-skeleton-loader
          type="rect"
          :height="473"
          :rounded="true"
          class="w-full mb-20"
          :radius="5"
          animation="wave"
        />
      </div>
      <div class="preloader-main-right">
        <vue-skeleton-loader
          type="rect"
          :width="167"
          :height="26"
          :rounded="true"
          class="mb-20"
          :radius="5"
          animation="wave"
        />
        <vue-skeleton-loader
          type="rect"
          :height="164"
          :rounded="true"
          class="w-full mb-20"
          :radius="5"
          animation="wave"
        />
        <vue-skeleton-loader
          type="rect"
          :height="473"
          :rounded="true"
          class="w-full mb-20"
          :radius="5"
          animation="wave"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import VueSkeletonLoader from 'skeleton-loader-vue';
  import HeaderLoading from "./HeaderLoading";
  export default {
    name: 'AuctionLoading',
    props: ['status'],
    components: {HeaderLoading, VueSkeletonLoader},
    mounted() {
      document.body.style.overflowY = 'hidden'
      document.body.style.width = '99%'
    },
    beforeDestroy () {
      document.body.style.overflowY = 'auto'
      document.body.style.width = '100%'
    },
  }
</script>
<style scoped lang="scss">


  body, html, .preloader {
    z-index: 9999;
    background: #F5F5F5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;

    .w-full {
      width: 100% !important;
    }

    .mb-20 {
      margin-bottom: 20px;
    }

    .mt-37 {
      margin-top: 37px;
    }

    .justify-between {
      justify-content: space-between;
    }

    .mrl-35 {
      margin-right: 35px;
      margin-left: 35px;
    }

    .loader-bg {
      background: #e2e2e2;
      padding-bottom: 12px;
      padding-top: 12px;
    }

    .flex {
      display: flex;
    }

    .mr-2 {
      margin-right: 8px;
    }

    .mr-30 {
      margin-right: 30px;
    }


    &-text {
      display: flex;
      justify-content: center;
      padding: 5px;
    }

    &-main {
      display: flex;
      justify-content: space-between;
      background: #e8e8e8;
      padding: 25px 18% 184px;

      @media (max-width: 768px) {
        flex-direction: column;
        padding: 5px 20px 184px;
      }

      &-left {
        width: 43%;
        margin-right: 20px;

        @media (max-width: 768px) {
          width: 100%;
        }

      }

      &-right {
        width: 57%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }

    }
  }

</style>
