<template>
  <div class="header-info auction">
    <div class="wrapper">
      <div class="header-info__block" v-for="route in routes" :key="route.path">
        <router-link :to="route.to" :class="[ isActive(route.to) ? 'header-info__auction active' : 'header-info__auction']">{{route.text}}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AuctionsHeader',
  data () {
    return {
      routes: [
        { to: {name: 'Auction', params: { years: '1' } }, text: "1 YEAR Auction" },
        { to: {name: 'Auction', params: { years: '10' } }, text: "10 YEAR Auction" },
        { to: {name: 'Auction', params: { years: '100' } }, text: "100 YEAR Auction" },
      ]
    }
  },
  methods: {
    isActive (data) {
      if (this.$route.name === data.name) {
        return true
      } else {
        return false
      }
    },
  }
}
</script>
<style scoped lang="scss">
.header {
  &-info {
    &.auction {
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(10px);
      padding: 12px 0;
      border-bottom: 1px solid #DFDFDF;
      .wrapper {
        justify-content: center;
        @media (max-width: 768px) {
          overflow-x: auto;
        }
        @media (max-width: 576px) {
          justify-content: flex-start;
          padding-bottom: 10px;
        }
      }
      @media (max-width: 768px) {
        padding: 12px 0 2px;
      }
    }
    &__block {
      &:not(:last-child) {
        margin-right: 35px;
        @media (max-width: 768px) {
          margin-right: 14px;
        }
      }
    }
    &__auction {
      display: block;
      text-decoration: none;
      padding: 12px 22px;
      border-radius: 20px;
      background: #F8F8F8;
      border: 1px solid transparent;
      font-size: 20px;
      line-height: 28px;
      color: #000000;
      transition: .35s;
      cursor: pointer;
      white-space: nowrap;
      @media (max-width: 768px) {
        padding: 8px 18px;
        font-size: 18px;
        line-height: 26px;
      }
      &:hover {
        background: #F0F0F0;
      }
      &.exact-active {
        background: #F8F8F8;
        border: 1px solid rgba(237, 125, 55, 0.5);
        color: #ED7D37;
      }
    }
  }
}
</style>
