var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-info auction"
  }, [_c('div', {
    staticClass: "wrapper"
  }, _vm._l(_vm.routes, function (route) {
    return _c('div', {
      key: route.path,
      staticClass: "header-info__block"
    }, [_c('router-link', {
      class: [_vm.isActive(route.to) ? 'header-info__auction active' : 'header-info__auction'],
      attrs: {
        "to": route.to
      }
    }, [_vm._v(_vm._s(route.text))])], 1);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }